.banner-bg{
    background-image: url(../assets/img/banner-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.main-banner{
    margin-top: 125px;
}
.bg-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #0032679d;
    /* opacity: .7; */
    height: inherit;
}
.banner-bg{
    height: calc(100vh - 300px);
}
.banner-bg h1{
    font-size: 2.5rem;
    color: white;
}
.banner-bg p{

font-weight: 400;
    max-width: 700px;
    margin: 0 auto;
    letter-spacing: 1px;
    font-size: 1rem;
    color: white;
}
.btn-flex{
    display: flex;
    align-items: center;
    gap:10px;
margin-top: 20px;


}
.btn-flex a,.link-btn{
       display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;  
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem; 
    border-radius: 0.25rem;
}  .btn-royalblue {background-color: royalblue !important;
    color: white !important;
    
    
}  .white-btn {background-color: white !important;}
@media (min-width:768px) {
 .bg-overlay p{
    font-size: 1.2rem;
 }
 .bg-overlay h1{
    font-size: 4rem;
 }   
}