.contact-form{
    margin: 0 auto; 
}
.input-main{
    margin-bottom: 1rem;
}
.input-main input,
.input-main textarea

{
        display: block;
    width: 100%; 
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400; 
    color: #495057;
    background-color: #fff; 
    border: 1px solid #ced4da;
    border-radius:8px; 
}
.disabled-btn{
    cursor: not-allowed;
    background-color: rgba(29, 67, 180, 0.521) !important;
}
.warning{
    text-align: left;
}
.alert-danger.left-align{
    color: red;

}
.left-align{
    text-align: left;
    margin: 5px 0;
}
.sub-btn{
    height: 46px;
}
.text-white{
    color: white;
}