.info-nav {
  background-color: #2f4e72;
  padding: 0.75rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.flex-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sticky {
  position: fixed;
  width: 100%;
  z-index: 99888;
  top: 0;
  animation: mymove 1s forwards;
}
.main-header {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
}
.right-info i {
  color: white;
}
.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.info-line {
  color: white;
  font-size: 16px;
}
.right-info {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
}
.navigation-header {
  box-shadow: 0 2px 6px rgba(43, 73, 111, 0.2);
  background-color: #f8f9fa !important;
}
.main-nav {
  display: flex;
  padding: 0.75rem 0;
  justify-content: space-between;
  align-items: center;
}
.nav-logo img {
  width: 200px;
  height: 100px;
}
.header-navigation {
  display: flex;
  gap: 15px;
}
.header-navigation a {
  color: #4e4e4e !important;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
}
.header-navigation a:hover {
  color: royalblue !important;
}
.header-navigation a.active {
  color: royalblue !important;
}

.mbl-ico {
  display: none;
}
@media (max-width: 600px) {
  .mbl-ico {
    display: block;
  }
  .mbl-ico i {
    font-size: 25px;
  }
  .info-nav {
    display: none;
  }
  .header-navigation {
    position: absolute;
    flex-direction: column;
    top: 74px;
    background: #f8f9fa;
    width: 100%;
    left: 0;
    overflow: hidden;
    height: 0;
    transition: all 0.3s ease;
  }
  .main-banner {
    margin-top: 74px !important;
  }
  .full-height {
    height: 185px !important;
    transition: all 0.3s ease;
    padding-bottom: 20px;
  }
}

@keyframes mymove {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}
