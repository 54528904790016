.team-flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;

}
.img img{
    width: 200px;
}
.team-member{
    width: 31%;
    padding: 30px ;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 10px 30px 0 rgba(50,50,50,.16);
}
.team-desc{
    margin-top: 5px;
    font-size: .9rem;
}
.text-left{
    text-align: left;
}
.titleName{
    font-size: 1.4rem;
    font-weight: 500;
}
.img{
    max-height: 200px;
    overflow: hidden;
}
@media (max-width:990px) {
    .team-member{
        width: 48%;
    }
    
}
@media (max-width:767px) {
     .team-member{
        width: 100%;
    }
    
}
