.heading-block h1 {
  font-weight: 700;
  color: #4e4e4e;
  position: relative;
  font-size: 1.7rem;
  margin-bottom: 2rem;
}
.royalblue {
  color: royalblue;
}
.heading-block p{
    max-width: 700px;
    color: #6f6f6f;
    font-size: .9rem;
    margin: 0 auto;
}
.heading-block h1::after {
  content: "";
  display: block;
  height: 3px;
  width: 70px;
  background: royalblue;
  position: absolute;
  top: 45px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
